<template>
  <div class="account-page">
    <b-spinner v-if="!link"/>

    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />

    <div class="iframe-wrap">
      <iframe
        v-if="link"
        :src="link"
        width="100%"
        height="100%"
        frameborder="0"
      />
    </div>
  </div>
</template>

<script>
  import { amsClient } from '@/service/ams';
  import normaliseMetaTags from '@/utils/normaliseMetaTags';

  export default {
    name: 'WebPage',
    data() {
      return {
        link: null,
        metadata: {},
      };
    },
    watch: {
      '$route': {
        immediate: true,
        handler(route) {
          this.loadPage(route.params.id);
        },
      },
    },
    methods: {
      async loadPage(id) {
        this.link = null;

        const { head } = await amsClient.callAms(`/get_item/${id}`);
        
        this.metadata = normaliseMetaTags(head);
        this.link = head.scheme_web;
      },
    },
  };
</script>

<style lang="scss" scoped>
	.account-page {
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    /* @media (max-width: 1025px) {
      min-height: calc(100vh - 55px);
    } */

    .spinner-border {
      position: absolute;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
      width: 40px;
      height: 40px;
    }

    .iframe-wrap {
      flex: 1;

      iframe {
        height: 100%;

        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }
      }
    }
  }
</style>
